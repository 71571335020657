import { inject, Injectable } from '@angular/core';
import { Favorite } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { GthEventItemModel } from '@sentinels/models';
import firebase from 'firebase/compat/app';
import {
  first, lastValueFrom, map, Observable, of, switchMap, zip,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FirestoreService } from '../core/firebase.service';
import { EventItemService } from './event-items.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class FavoritesService extends FirestoreService<Favorite> {
    private eventsService = inject(EventItemService);

    private usersService = inject(UserService);

    basePath = 'favorites';

    async toggleUserFavoriteEvent(userId: string, eventId: string) {
      const userDocRef = this.usersService.getUserDocRef(userId);

      const favoritesColRef = userDocRef.collection(this.basePath);

      try {
        const favoriteDoc = await lastValueFrom(
          favoritesColRef.doc(eventId).get().pipe(first()),
        );

        if (favoriteDoc.exists) {
          return favoritesColRef.doc(eventId).delete().then(() => true);
        } else {
          const favoriteDocData: Favorite = {
            userId,
            eventId,
            created: firebase.firestore.Timestamp.now(),
          };

          return favoritesColRef.doc(eventId)
          .set(favoriteDocData)
          .then(() => true);
        }
      } catch (error: unknown) {
        console.error(this.basePath,
          `Error toggling favorite event: ${eventId}`, error);

        return false;
      }
    }

    getUserFavoriteEvents$(userId: string): Observable<GthEventItemModel[]> {
      const userDocRef = this.usersService.getUserDocRef(userId);

      const favoritesColRef = userDocRef.collection<Favorite>(this.basePath);

      return favoritesColRef.valueChanges().pipe(
        map((favorites) => {
          return favorites.map((favorite) => {
            const eventId = favorite.eventId;

            return this.eventsService.getEvent(eventId);
          });
        }),
        switchMap((eventObservables) => {
          return eventObservables.length ? zip(eventObservables) : of([]);
        }),
        map((eventModels) => eventModels.filter((eventModel) => !!eventModel)),
        catchError((error: unknown) => {
          console.error(`Error getting favorite events for ${userId}`, error);

          return of([]);
        }),
      );
    }

    async getUserFavoriteEvents(userId: string) {
        const userDocRef = this.usersService.getUserDocRef(userId);

        const favoritesColRef = userDocRef.collection(this.basePath);

        try {
          const favoritesSnapshot = await lastValueFrom(favoritesColRef.get());

          const favoriteEventIds = favoritesSnapshot.docs.map((doc) => doc.get('eventId'));

          const favoriteEvents = await Promise.all(
            favoriteEventIds.map((eventId) => this.eventsService.getEventById(eventId)),
          );

          return favoriteEvents.filter((event) => !!event);
        } catch (error: unknown) {
          console.error(`Error getting favorite events for ${userId}`, error);

          return [];
        }
      }

      async getUsersWhoFavoritesEvent(eventId: string) {
        try {
          const favoritesColGroup = this.firestore.collectionGroup(
            DBUtil.Favorites,
          (ref) => ref.where(firebase.firestore.FieldPath.documentId(), '==', eventId),
          );

          const querySnapshot = await lastValueFrom(favoritesColGroup.get());

          if (querySnapshot.empty) return [];

          const userIds = querySnapshot.docs.map((doc) => doc.get('userId'));

          const userModels = await Promise.all(
            userIds.map((userId) => this.usersService.getAsyncUser(userId)),
          );

          return userModels.filter((user) => !!user);
        } catch (error: unknown) {
          console.error(`Error getting users who favorites this event: ${eventId}`, error);

          return [];
        }
      }
}
